<template>
  <div class="ApprovalTag">
    <!-- 任务确认 -->
    <van-nav-bar :title="'任务确认'"
                 left-text
                 left-arrow
                 @click-left="onClickLeft"
                 :fixed="true" />
    <div class="content">
      <van-search v-model="searchVal"
                  shape="round"
                  :placeholder="'搜索'"
                  @search="onSearch" />
      <van-pull-refresh v-model="isLoadingAwait"
                        @refresh="onRefreshAwait">
        <van-list v-model="loadingAwait"
                  :finished="finishedAwait"
                  :finished-text="$t('module.noMore')"
                  @load="onLoadAwait">
          <div class="zb_group"
               v-for="(item, index) in assessList"
               :key="index">
            <div class="zb_group">
              <van-cell class="ass_cell"
                        size="large"
                        :title="item.taskname"
                        :label="`${item.empname}-${item.deptname}`"
                        @click="goToApply(item)">
                <template slot="label">
                  {{ item.empname }}-{{ item.deptname }}<br />
                  周期：{{ item.khtimename }}
                </template>
                <template>
                  <div style="color:red">权重：{{ item.taskper }}</div>
                  <div>{{ item.reqdate }}</div>
                  <div class="task_ischeck">
                    <div v-if="item.task_is_ischeck"
                         style="color:red;">已确认！</div>
                    <van-button v-else
                                type="warning"
                                size="mini"
                                @click.stop="confirm(item)">我要确认</van-button>
                  </div>
                </template>
              </van-cell>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import {
  getMyGsTaskList,
  checkMyTask
} from '@api/wxjx.js'
export default {
  data () {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    let khtimeid = this.$route.query.khtimeid
    return {
      khtimeid,
      userInfo,
      isLoadingAwait: false,
      loadingAwait: false,
      finishedAwait: false,
      assessList: [],
      sureList: [],
      checkList: [],
      noList: [],
      searchVal: '',
      sureNum: '',
      noNum: '',
      checkNum: ''
    }
  },
  watch: {
    searchVal (n) {
      this.onSearch()
    }
  },
  created () {
    this.khtimeid = this.$route.query.khtimeid
  },
  methods: {
    onSearch () {
      if (this.searchVal)      {
        this.assessList = this.assessList.filter(
          item =>
            item.empname.indexOf(this.searchVal) > -1 ||
            item.deptname.indexOf(this.searchVal) > -1 ||
            item.taskname.indexOf(this.searchVal) > -1
        )
      } else      {
        this.getList()
      }
    },
    onClickLeft () {
      this.$router.push({
        path: '/jxMyAssTarget'
      })
    },
    // 我要确认
    confirm (item) {
      checkMyTask({
        autoid: item.autoid,
        empid: this.userInfo.empid
      }).then(res => {
        if (res.data[0].info)        {
          Toast.fail(res.data[0].info)
        } else        {
          Toast.success('确认成功！')
          this.onLoadAwait()
        }
      })
    },
    // 9.1.获取待审批的360考核方案列表
    getList () {
      let _this = this
      getMyGsTaskList({ khtimeid: this.khtimeid, empid: this.userInfo.empid }).then(res => {
        if (res.data.length == 0)        {
          _this.finishedAwait = true
          _this.sureNum = ''
          // 无数据
        } else        {
          _this.sureNum = '(' + res.data.length + ')'
          _this.sureList = res.data
          _this.loadingAwait = false
          _this.finishedAwait = true
        }
        _this.assessList = JSON.parse(JSON.stringify(_this.sureList))
      })
    },
    onLoadAwait () {
      // 异步更新数据
      if (this.isLoadingAwait)      {
        this.assessList = []
        this.isLoadingAwait = false
      }
      this.getList()
    },
    onRefreshAwait () {
      this.assessList = []
      this.finishedAwait = false
      this.loadingAwait = true
      this.onLoadAwait()
      // Toast('刷新成功')
    },
    // tab切换事件
    changeAct (name, title) {
      this.onRefreshAwait()
    },
    // 发起指标申请
    goToApply (item) {
      this.$router.push({
        path: '/myGsDetails',
        query: {
          title: item.taskname,
          autoid: item.autoid,
          khtimeid: this.khtimeid
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ApprovalTag {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    // overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 92px);
    /deep/.van-tabs {
      .van-tab {
        .van-tab__text {
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: bold;
        }
      }
    }
    .van-pull-refresh {
      margin-top: 20px;
      overflow: auto;
      height: calc(100% - 132px);
    }
    .zb_group {
      .ass_cell {
        // margin: 10px 0;
        margin-bottom: 10px;
        .van-cell__title {
          flex: 3;
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          line-height: 54px;
          color: #333333;
        }
        .van-cell__value {
          position: relative;
          .task_ischeck {
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }
      }
    }
  }
}
</style>
